<!--
  PACKAGE_NAME : src\pages\ewm\hr\work\schedule\list.vue
  FILE_NAME : list
  AUTHOR : bykim
  DATE : 2024-03-14
  DESCRIPTION : 근무스케줄 메인 화면
-->
<template>
	<div class="container">
		<Tabs ref="tabs" :selected-index="tabSelectedIndex" :tab-type="1" tab-css-class="customTabCSS">
			<Tab title="근무스케줄 관리">
				<Management ref="managementRef" />
			</Tab>
			<Tab title="근무스케줄 변경이력">
        <History ref="historyRef" />
			</Tab>
		</Tabs>
	</div>
</template>

<script>
import Tab from '@/components/common/tab.vue';
import Tabs from '@/components/common/tabs.vue';
import Management from '@/pages/ewm/hr/work/schedule/management.vue';
import History from '@/pages/ewm/hr/work/schedule/history.vue';

export default {
	components: { History, Management, Tabs, Tab },
	props: {},
	data() {
		return {
			tabIndex: 0,
			tabs: {
				selectedIndex: this.$store.getters.getTabHists[this.$router.currentRoute.path],
			},
		};
	},
	computed: {},
	methods: {
		async tabSelectedIndex(index) {
			this.tabIndex = index;
		},
	},
	created() {},
	mounted() {},
};
</script>

<style lang="scss" scoped>
  ::v-deep .customTabCSS {
    padding: 0 20px 0 20px;
  }
  ::v-deep .dx-datagrid-header-panel {
    padding: 10px 0 0 0;
    border-bottom: none;
  }
</style>
